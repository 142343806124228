// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-how-can-constitutions-advance-equality-js": () => import("./../src/templates/how-can-constitutions-advance-equality.js" /* webpackChunkName: "component---src-templates-how-can-constitutions-advance-equality-js" */),
  "component---src-templates-making-and-changing-constitutions-js": () => import("./../src/templates/making-and-changing-constitutions.js" /* webpackChunkName: "component---src-templates-making-and-changing-constitutions-js" */),
  "component---src-templates-how-does-your-constitution-compare-js": () => import("./../src/templates/how-does-your-constitution-compare.js" /* webpackChunkName: "component---src-templates-how-does-your-constitution-compare-js" */),
  "component---src-templates-monitoring-and-accountability-js": () => import("./../src/templates/monitoring-and-accountability.js" /* webpackChunkName: "component---src-templates-monitoring-and-accountability-js" */),
  "component---src-templates-teaching-and-learning-js": () => import("./../src/templates/teaching-and-learning.js" /* webpackChunkName: "component---src-templates-teaching-and-learning-js" */),
  "component---src-templates-search-js": () => import("./../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

