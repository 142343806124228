/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(() => {
      const el = document.querySelector(hash)
      if (el) {
        return window.scrollTo(0, el.offsetTop - 200)
      }
      return false
    }, 10)
  }
}
